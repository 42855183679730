.settings-sider-options {
  width: 310px;
  position: absolute;
  right: -310px;
  top: 0;
  height: 100%;
  z-index: 2;
  background-color: rgba(255, 255, 255, 0.3);
  padding: 20px;
  overflow-x: hidden;
  overflow-y: auto;
  transition: all ease-in-out 0.3s;

  .container {
    margin-top: 5px;
    padding-bottom: 50px;
    z-index: 10;
    .section {
      margin-bottom: 20px;
      margin-right: 85px;
      .section-title {
        text-transform: uppercase;
        color: #FFF;
        font-size: 20px;
      }
    }

    .avatar {
      width: 75px;
      height: auto;
      cursor: pointer;
    }

    .section-buttons {
      text-align: center;
      margin-right: 85px;
      margin-bottom: 10px;
      margin-top: -20px;
      button {
        text-transform: uppercase;
        background: transparent;
        border: 1px solid #FFF;
        color: #FFF;
        font-size: 14px;
        padding: 5px 10px;
        position: relative;
        outline: none;
      }
    }
  }

  &.opened {
    right: 0;
  }
}