.expand-modal__confirm {
    position: fixed;
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    padding: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: $dashboard-color;
    background: rgba(0, 0, 0, 0.9);
    transition: opacity .5s ease-in-out 0s;
    button {
        margin: 0 10px;
    }
}
.expand-modal {
    $self: &;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: -1;
    opacity: 0;
    visibility: hidden;
    transition: all .25s;
    position: absolute;
    background-color: rgba(0, 0, 0, 0.45);
    &.chosen {
        z-index: 9999;
        visibility: visible;
        opacity: 1;
        top: 0;
        left: 0;
        #{ $self }__image {
            z-index: 9999;
            img {
                z-index: 9999;
            }
        }
    }
    &__content {
        object-fit: contain;
        max-width: 80vw;
        max-height: 80vh;
        position: relative;
        &__image {
            min-width: 100%;
            min-height: 100%;
            max-width: 80vw;
            max-height: 80vh;
        }
        &__text {
            background-color: #ffffff;
            min-width: 40vw;
            max-height: 80vh;
            overflow: auto;
            white-space: pre-wrap;
            padding: 15px;
            border: 3px solid #55f1e7;
            border-radius: 5px;
            box-shadow: 0 0 0 0.2rem rgba(15, 184, 173, 0.25);
            &::-webkit-scrollbar {
                height: 8px;
                width: 8px;
            }
            &::-webkit-scrollbar-track {
                background-color: #0000;
            }
            &::-webkit-scrollbar-thumb {
                border-radius: 5px;
                background-color: #d8d8d8;
            }
        }
        &__icons {
            font-size: 20px;
            color: rgba($dashboard-color, 0.5);
            position: absolute;
            top: -25px;
            cursor: pointer;
            display: flex;
            i {
                margin-right: 20px;
            }
        }
        &__mark {
            font-size: 30px;
            color: $white;
            position: absolute;
            right: -30px;
            top: -30px;
            cursor: pointer;
        }
    }
}
