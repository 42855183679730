.search-bar {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
    background-color: transparent;
    position: absolute;
    right: 87px;
    bottom: 30px;
    &__list {
        display: flex;
        flex-direction: column;
        width: 100%;
        &__item {
            display: flex;
            flex-direction: row;
            align-items: center;
            margin-bottom: 10px;
            &:hover {
                cursor: pointer;
                font-weight: bold;
            }
            div {
                margin-left: 10px;
                height: 20px;
                width: 20px;
                background-size: cover;
                border-radius: 50%;
            }
            i {
                margin-left: 10px;
                font-size: 20px;
                color: $dashboard-color;
            }
            span {
                margin-left: 10px;
                color: $dashboard-color;
            }
        }
    }
    &__form {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        &__input {
            border: 1px solid $dashboard-color;
            border-radius: 20px;
            padding: 5px;
            input {
                background-color: inherit;
                border: none;
                padding-left: 5px;
                color: $dashboard-color;
                outline: none;
                &::placeholder {
                    color: #FFF;
                }
                &:focus {
                    outline-color: transparent;
                    border-radius: 25px;
                }
            }
        }
        &__icon {
            color: $dashboard-color;
            cursor: pointer;
            font-weight: bold;
            margin-left: 10px;
        }
    }
}
