.controls {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    z-index: 3;
    height: 0;
    &__group {
        display: flex;
        flex-direction: column;
        bottom: 20px;
        position: relative;
        &.left {
            left: 20px;
        }
        &.right {
            right: 20px;
        }
    }
}
.control {
    z-index: 3;
    cursor: pointer;
    color: $dashboard-color;
    border-radius: 50%;
    font-size: 1.25rem;
    width: 50px;
    height: 50px;
    padding: 1%;
    border: 1px solid $dashboard-color;
    margin: 6px;
    text-align: center;
    display: flex;
    &.inline {
        display: inline-flex;
        &.active-item {
            box-shadow: 0 0 10px #fff;
        }
    }
    align-items: center;
    justify-content: center;
    -webkit-transition: color 0.3s ease;
    &.control-no-border {
        border: none;
    }
    &:hover {
        background-color: rgba($dashboard-color, 0.15);
    }
    &:active {
        border: 1px solid rgba($black, 1);
        transition: color 0.3s ease;
        color: $black;
        transform: scale(1.125);
    }
    i {
        display: flex;
        align-self: center;
    }
}
