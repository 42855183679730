.category-select {
  &__title {
    color: #FFF;
    font-size: 18px;
    margin-bottom: 5px;
    display: inline-block;
  }

  .category-select-component {
    cursor: pointer;
    font-size: 12px !important;
    color: #FFF !important;

    div[class$='-menu'] {
      background: transparent;
      margin-top: 0;
      border-radius: 0;
      box-shadow: none;

      div[class$='-MenuList'] {
        padding-top: 0;

        div[class$='-option'] {
          color: #FFF;
          padding: 4px 12px;
          text-transform: uppercase;
          background: rgba(255, 255, 255, 0.2);
          cursor: pointer;

          &:hover, &:active, &:focus {
            background-color: rgba(255, 255, 255, 0.4);
          }
        }
      }
    }

    div[class$='-control'] {
      box-shadow: none;
      border-radius: 0;
      background: rgba(255, 255, 255, 0.2);
      border: none;
      min-height: 30px;
      text-transform: uppercase;
      // SelectBox
      & > div {
        // Placeholder
        padding-right: 0;

        div[class$='-placeholder'] {
          color: #FFF;
        }

        & > div {
          color: #FFF !important;
        }
      }

      // Separator
      span[class$='-indicatorSeparator'] {
        display: none;
      }
      div[class$='-indicatorContainer'] {
        color: #FFF;
        padding: 0 5px 0 0;
      }
    }
  }
}