.image-uploader {
    display: flex;
    flex-direction: column;
    align-items: center;
    &__label {
        color: #fff;
        font-weight: bold;
        font-size: 30px;
        cursor: pointer;
        margin: 10px;
        @media screen and (max-width: 450px) {
            font-size: 20px;
        }
    }
    &__container {
        width: 400px;
        max-width: 90vw;
        height: 400px;
        max-height: 55vh;
        display: flex;
        justify-content: center;
        align-items: center;
        &__poster {
            display: block;
            max-width: 100%;
            max-height: 100%;
        }
    }
    &__control {
        display: flex;
        width: 100%;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        &-rotate {
            color: #fff;
            font-size: 30px;
            cursor: pointer;
            @media screen and (max-width: 450px) {
                font-size: 20px;
            }
        }
    }
}
