// Fonts
@import url('https://fonts.googleapis.com/css?family=Raleway');
// Animate.css
@import url('./lib/animate.css');

// Variables
@import 'variables';

// Bootstrap
@import '~bootstrap/scss/bootstrap.scss';



:root {
    --salt: 150
}

html {
    min-height: 100vh;
}

body {
    min-height: 100%;
    //height: 100vh;
    font-family: $font-family-sans-serif !important;
    background: linear-gradient(142deg, $lt-gradient-1 0%, $lt-gradient-2 51%, $lt-gradient-3 75%);
}

#app {
    background: transparent!important;
}

.btn {
    text-transform: uppercase;
}

#logo {
    a {
        display:block;
        background-image: url('/images/logo.png');
        background-repeat: no-repeat;
        background-position: center;
        min-height: 100px;
        max-height: 250px;
    }
}

#header-nav {
    font-size:1.05rem;
    line-height: 2rem;
}

.user-dashboard {
    color: $dashboard-color;
}

.react-dashboard {
    position: absolute;
    width: 100%;
    height: 100vh;
    overflow: hidden;
    padding: 0 !important;
}

.close {
    opacity: 1 !important;
    visibility: visible !important;
}

.invisible {
    opacity: 0 !important;
    height: 0 !important;
    z-index: -999 !important;
    visibility: hidden !important;
}

@import 'login.scss';

// React Styles
@import './react/control.scss';
@import './react/search-bar.scss';
@import './react/display-toggle.scss';

// Edit Caption
@import 'react/modals/caption-edit';
// Comments Popup
@import 'react/modals/comments-popup';
// Settings Popup
@import 'react/modals/settings-popup';
// Themes
@import './react/themes.scss';
// Bubbles
@import 'react/bubbles/bubbles';
// Bubble
@import 'react/bubbles/bubble';
// Visited Profiles
@import './react/visited-list';
// Dropzone
@import './react/dropzone/index';
// Forms
@import 'react/forms/addImagePost';
// React Switch
@import './react/switch';
// Expand Modal
@import './react/modals/expand-modal';
// User Display
@import './react/user-display/index.scss';
// Common for Modals
@import './react/modals/common-modal';
// ImageUploader
@import './react/modals/image-uploader';
// Preloader
@import './react/preloader';
// Login page floated items
@import './react/floated';

// Add Post Modal
@import './react/forms/post-fields';
@import './react/modals/add-post-modal';

// New Layout Modal
@import './react/modals/new-layout-modal';

// Add Text Post
@import './react/forms/addTextPost';

// Category Select
@import './react/category-select';

// Settings Sider Options
@import './react/settings-sider-options';

/* width */
::-webkit-scrollbar {
    width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
    background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #555;
}

.slick-slide {
    outline: none !important;
}

.BrainhubCarousel__trackContainer {
    margin: 0 10px;
}