.add-text-post {
  height: 100%;
  .fields-group {
    &__bottom {
      margin-top: 10px;
      display: flex;
      justify-content: space-between;
      align-items: flex-end;
      &__item {
        flex: 1;
      }
      &__item:first-of-type {
        margin-right: 25px;
      }
    }
  }
  .button-group {
    position: absolute;
    bottom: -90px;
    right: -30px;
    button {
      background: transparent;
      border: 1px solid #FFF;
      color: #FFF;
      font-size: 18px;
      padding: 5px 15px;
      position: relative;
      outline: none;
    }
  }
}