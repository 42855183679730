.post-fields {
  .caption {
    position: relative;

    textarea {
      width: 100%;
      height: 100px;
      outline: none;
      resize: none;
      color: #FFF;
      padding: 10px;
      background: transparent;
      border: 1px solid #FFF;

      &::placeholder {
        color: #FFF;
        font-size: 12px;
      }
    }
  }

  .corner-field {
    position: absolute;
    &.bottom-left {
      left: -1px;
      bottom: 5px;
      height: 50px;
      width: 50px;
      z-index: -1;
      border-left: 1px solid #FFF;
      border-bottom: 1px solid #FFF;
    }
    &.top-right {
      right: -1px;
      top: -1px;
      height: 50px;
      width: 50px;
      z-index: -1;
      border-right: 1px solid #FFF;
      border-top: 1px solid #FFF;
    }
  }
}