.user-display {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 2;
    display: flex;
    margin: 20px 20px 0 0;
    &.deactived {
        opacity: 0;
        visibility: hidden;
    }
    &__info {
        display: flex;
        flex-direction: column;
        min-width: 120px;
        &__btn {
            width: fit-content;
            margin-left: 63px;
        }
        &__link {
            cursor: pointer;
        }
        h4 {
            font-weight: bold;
            color: $dashboard-color;
            font-size: 1rem;
            text-transform: uppercase;
            margin-bottom: 0.125rem;
        }
        &__meta {
            display: flex;
            flex-direction: column;
            .lt-count-container {
                font-weight: bold;
                text-transform: uppercase;
                font-size: .80rem;
                .lt-count,
                .lt-count-name {
                    color: $dashboard-color;
                    padding-right: 10px;
                }
            }
        }
    }
}
.user-logo {
    &__wrapper {
        display: flex;
        align-items: center;
        margin: 10.5px
    }
    &__outer {
        width: 51px;
        height: 51px;
        background-color: rgba(255, 255, 255, 0.25);
        border: 1px solid $dashboard-color;
        border-radius: 50%;
        overflow: hidden;
        &.active-item {
            box-shadow: 0 0 10px #fff;
        }
    }
    &__inner {
        width: 100%;
        height: 100%;
        background-size: cover;
    }
    &__icon {
        font-size: 50px;
        color: $dashboard-color;
    }
    &__hover {
        cursor: pointer;
        &:hover {
            color: rgba(0, 0, 0, 0.35);
        }
    }
}
