.bubble-form {
    padding: 2%;
    width: 480px;
    height: 100%;
    border: 3px solid rgba($lt-gradient-3, 0.45);
    border-radius: 50%;
    background-color: rgba(255, 255, 255, 0.2);

    .btn {
        letter-spacing: 0.05em;
    }
    &:hover {
        background-color: rgba(255, 255, 255, 0.25 );
    }
    .card {
        background-color: rgba(255, 255, 255, 0);
        border: none;
    }
    .card-body{
        padding: 13%;
    }
    .form-group {
        width: 100%;
        margin-bottom: 6px;
        label {
            margin-bottom: 1px;
            padding-bottom: 2px;
        }
        input {
            padding-top: 2px;
        }
    }

    label {
        font-weight: bold;
    }

    .naked-link {
        color: black;
        text-transform: uppercase;
        font-weight: bold;
        padding: 2px;
    }
}
.signup-option {
    background-color: rgba(255, 255, 255, 0.2);
    border: 1px solid rgba($lt-gradient-3, 0.5);
    border-radius: 50%;
    width: 325px;
    height: 325px;
    display: flex;
    flex-direction: column;
    text-align: center;
    padding: 7%;

    margin: 2%;
    cursor: pointer;

    &.disabled {
        cursor: not-allowed;
    }

    &:hover {
        background-color: rgba(255, 255, 255, 0.25);
        box-shadow: inset 1px 3px 1px 3px rgba($lt-gradient-3, 0.85);
    }

    .option-icon {
        padding: 10%;
    }

    .option-name {
        font-weight: bold;
        font-size: 1.85rem;
    }
    .option-description {
        margin-top: 5%;
        font-size: 0.9rem;
        font-weight: bold;
        text-transform: lowercase;
    }

    i {
        font-size: 4.5rem;
        color: rgba(255, 255, 255, 0.75);
    }
}
.agreement-form {
    display: flex;
    align-items: center;

    &__label {
        margin: 0 5px 0;
        color: $dashboard-color;
    }
    &__checkbox {
        height: 0;
        width: 0;
        input {
            opacity: 0;
        }
    }
    &__text {
        color: $dashboard-color;
        font-size: 10px;
        &:hover{
            cursor: pointer;
            text-decoration: underline;
        }
    }
}
