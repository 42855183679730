// Body
$body-bg: #f8fafc;

// Typography
$font-family-sans-serif: 'Raleway', sans-serif;
$font-size-base: 0.9rem;
$line-height-base: 1.6;

// Colors
$blue: #3490dc;
$indigo: #6574cd;
$purple: #9561e2;
$pink: #f66d9b;
$red: #e3342f;
$orange: #f6993f;
$yellow: #ffed4a;
$green: #38c172;
$teal: #4dc0b5;
$cyan: #6cb2eb;
$white: #ffffff;
$black: #000000;

$lt-orange: #ff6634;
$lt-gradient-1: #0fb8ad;
$lt-gradient-2: #1fc8db;
$lt-gradient-3: #2cb5e8;

$primary: $lt-gradient-1 !default;
$secondary: $lt-orange !default;

$dashboard-color: #fff;
// $success: $green !default;
// $info: $cyan !default;
// $warning: $yellow !default;
// $danger: $red !default;
// $light: $gray-100 !default;
// $dark: $gray-800 !default;
