.add-post-modal {
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.2);
  backdrop-filter: blur(5px);
  position: absolute;
  z-index: 3;
  display: flex;
  align-items: center;
  justify-content: center;

  .post-modal-container {
    width: 100%;
    height: 100%;
    max-width: 450px;
    max-height: 550px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    background-color: rgba(255, 255, 255, 0.2);

    .post-modal-content {
      width: 80%;
      height: 90%;
      display: flex;
      flex-direction: column;
      position: relative;
      .type-selection {
        margin-bottom: 10px;
        &__button {
          width: 80px;
          text-align: center;
          cursor: pointer;
          display: inline-block;
          font-size: 20px;
          padding: 1px;
          color: #FFFF;
          &.selected {
            background: #FFF;
            color: #000;
          }
        }
      }
    }

    .corner {
      position: absolute;
      &.top-right {
        right: -15px;
        top: -20px;
        transform: rotate(45deg);
      }
      &.bottom-left {
        left: -15px;
        bottom: -20px;
        transform: rotate(-135deg);
      }
      i {
        color: #FFF;
      }
    }
  }
}

