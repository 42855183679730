.new-layout-modal {
  width: 100vw;
  height: 100vh;
  backdrop-filter: blur(5px);
  position: absolute;
  z-index: 3;
  display: flex;
  align-items: center;
  justify-content: center;

  .new-layout-container {
    width: 100%;
    height: 100%;
    max-width: 80%;
    max-height: 80%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;

    .new-layout-content {
      width: 80%;
      height: 90%;
      display: flex;
      flex-direction: column;
      position: relative;

      .navigation-items {
        .item {
          text-transform: uppercase;
          &.selected {
            text-decoration: underline;
          }
        }
      }

      .block-list {
        display: flex;
        justify-content: space-between;
        .section-block {
          border: 1px solid rgba(255,255,255,0.5);
          background-color: rgba(0,0,0,0.25);
          padding: 30px;
          outline: none;
          flex-basis: 48%;

          .total-title {
            font-size: 48px;
            color: #bcfaf9;
            line-height: 1;
            margin-bottom: 15px;
            span {
              display: inline-block;
              position: relative;
              top: -5px;
              margin: 0 5px;
            }
          }

          .left-total {
            display: flex;
            .total-title {
              width: fit-content;
              min-width: fit-content;
              font-size: 64px;
              margin-right: 10px;
              margin-top: 10px;
            }
          }

          .BrainhubCarouselItem {
            height: fit-content;
          }

          .head {
            color: rgba(255,255,255,0.95);
            font-weight: 600;
            letter-spacing: 1px;
            text-transform: uppercase;
            margin-bottom: 5px;
            font-size: 18px;
          }

          .main {
            margin-left: 20%;

            .circle-item {
              display: flex !important;
              flex-direction: column;
              justify-content: center;
              align-items: center;
              max-width: 70px;
              min-width: 70px;
              .image {
                width: 45px;
                height: 45px;
                border-radius: 50%;
                background-color: #FFF;
                display: flex;
                justify-content: center;
                align-items: center;
              }
              .category-icon {
                width: 22px;
                height: 22px;
                image-rendering: -webkit-optimize-contrast;
              }
              .text {
                margin-top: 10px;
                font-size: 12px;
                color: #FFF;
                font-weight: 300;
                text-align: center;
              }
            }
          }
        }
      }
    }

    .corner {
      position: absolute;
      border-radius: 0;
      width: 30px;
      height: 30px;
      &.top-left {
        left: -15px;
        top: -20px;
        border-left: 1px solid #FFF;
        border-top: 1px solid #FFF
      }
      &.bottom-right {
        right: -15px;
        bottom: -20px;
        border-right: 1px solid #FFF;
        border-bottom: 1px solid #FFF;
      }
    }
  }
}

