.edit-caption {
  opacity: 0;
  position: absolute;
  right: -25%;
  top: 675px;
  transition: all ease-in-out .3s;
  transform: translate3d(0, 0, 0);
  max-height: calc(100vh - 160px);
  width: 0;
  height: 0;
  .edit-form {
    position: relative;
    border-radius: 8px;
    width: 580px;
    height: 100px;
    margin-bottom: 15px;
    margin-left: 30px;
    background: rgba(255, 255, 255, 0.6);
    input {
      color: #ffffff;
      width: 490px;
      height: 90px;
      border: none;
      background: none;
      border-radius: .25rem;
      padding: .75rem 1rem;
      outline: none;
    }

    input::placeholder {
      color: rgba(255, 255, 255, 0.7);
    }

    button {
      border: none;
      background: none;

      i {
        color: white;
      }
    }
  }
  &.edit-active {
    opacity: 1;
    width: 580px;
    height: 100px;
  }
}