.bubble {
  position: absolute;
  display: block;
  border-radius: 50%;
  cursor: pointer;
  visibility: hidden;
  opacity: 0;
  transition: opacity ease-in-out .4s;
  border: 3px solid rgba($dashboard-color, 0.85);
  background-color: rgba($dashboard-color, 0.25);

  &__background {
    display: flex;
    align-items: center;
    justify-content: center;
    word-break: break-word;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    position: relative;
    width: 395px;
    height: 395px;
    border-radius: 50%;
    overflow: hidden;
  }

  &__around {
    width: 400px;
    height: 400px;
    position: absolute;
    top: 0;

    &__icon {
      font-size: 70px;
      color: $dashboard-color;
      border-radius: 50%;
    }

    &__small {
      top: -6px;
      left: -10px;
      z-index: 1;
      position: absolute;
      width: 75px;
      height: 75px;
      border-radius: 50%;
      overflow: hidden;
      background-position: center;
      background-size: cover;
      background-repeat: no-repeat;
    }

    &__actions {
      opacity: 0;
      visibility: hidden;
      transition: all .2s;

      &.visible {
        opacity: 1;
        visibility: visible;
      }

      i {
        height: 50px;
        width: 50px;
        position: absolute;
        border-radius: 50%;
        background-color: #fff;
        bottom: 0;
        right: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 25px;
        z-index: 1;
      }

      i:nth-of-type(1) {
        bottom: 45px;
        right: 25px;
      }

      i:nth-of-type(2) {
        bottom: 100px;
        right: -7px;
      }

      i:nth-of-type(3) {
        bottom: 160px;
        right: -20px;
      }

      i:nth-of-type(4) {
        right: 74px;
        bottom: 6px;
      }
    }
  }

  &__text {
    position: absolute;
    top: 430px;
    width: 100%;
    opacity: 0;
    visibility: hidden;
    transition: all .2s;

    &.visible {
      opacity: 1;
      visibility: visible;
    }

    &__description {
      background-color: rgba(255,255,255,0.3);
      color: #000;
      width: 100%;
      padding: 18px;
      border-radius: 0 30px 30px 30px;
      text-shadow: none !important;

      i {
        position: absolute;
        right: 20px;
      }

      h3 {
        font-weight: bold;
        font-size: 30px;
      }

      p {
        margin-bottom: 0;
      }
    }

    &__comment-section {
      width: 90%;
      float: right;

      &__add-comment {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 10px 0;
        transition: all .2s;
        height: 65px;

        .lt-add-comment {
          border: 1px solid $dashboard-color;
          border-radius: 25px;
          padding: 2%;
          flex: 1;

          input {
            background-color: inherit;
            border: none;
            padding-left: 5px;
            color: $white;

            &::placeholder {
              color: #FFF;
            }

            &:focus {
              outline-color: transparent;
              border-radius: 25px;
            }
          }
        }

        .lt-add-comment-icon {
          padding: 10px;
          color: $dashboard-color;
          cursor: pointer;
          font-weight: bold;
        }
      }

      &__comment {
        margin-bottom: 10px;
        padding: 10px 20px;
        background-color: rgb(192, 192, 192);
        border-radius: 20px 20px 0 20px;
        color: #fff;
        font-weight: bold;
      }
    }
  }
}
