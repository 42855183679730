.modal {
    &__wrapper {
        position: fixed;
        top: 0;
        left: 0;
        width: 100vw;
        height: 100vh;
        background-color: #000c;
        cursor: pointer;
        z-index: 9999;
        display: flex;
        align-items: center;
        justify-content: center
    }
    &__post {
        width: 50vw;
        @media screen and (max-width: 1024px) {
            width: 60vw;
        }
        @media screen and (max-width: 768px) {
            width: 70vw;
        }
    }
    &__container {
        position: absolute;
        display: flex;
        justify-content: center;
        height: 460px;
        cursor: default;
        @media screen and (max-width: 425px) {
            width: 80vw;
            height: 80vh;
        }
        @media screen and (max-height: 425px) {
            height: 80vh;
        }
        &-close {
            position: absolute;
            right: -30px;
            top: -30px;
            color: #fff;
            z-index: 9999;
            font-size: 30px;
            cursor: pointer;
            @media screen and (max-width: 450px) {
                right: -20px;
                top: -20px;
                font-size: 20px;
            }
        }
    }
}
