.comments__right {
  opacity: 0;
  position: absolute;
  bottom: 0;
  left: 450px;
  transition: all ease-in-out .3s;
  transform: translate3d(0, 0, 0);
  max-height: calc(100vh - 160px);
  overflow-y: auto;
  width: 0;
  height: 0;
  .add-comment {
    position: relative;
    border-radius: 8px;
    width: 580px;
    height: 100px;
    margin-bottom: 15px;
    margin-left: 30px;
    background: rgba(255, 255, 255, 0.6);
    input {
      color: #ffffff;
      width: 515px;
      height: 90px;
      border: none;
      background: none;
      border-radius: .25rem;
      padding: .75rem 1rem;
      outline: none;
    }
    input::placeholder {
       color: rgba(255, 255, 255, 0.7);
     }
    button {
      border: none;
      background: none;
      i{
        color: white;
      }
    }
  }
  .add-comment:after {
    content: '';
    position: absolute;
    display: block;
    width: 0;
    z-index: 1;
    border-style: solid;
    border-width: 0 0 30px 30px;
    border-color: transparent transparent rgba(255, 255, 255, 0.6) transparent;
    top: 20%;
    left: -30px;
    margin-top: -10px;
  }
  &__list {
    display: flex;
    flex-direction: column;
    width: 650px;
    height: 350px;
    margin-right: 0;
    overflow-y: auto;
    overflow-x: hidden;
    &::-webkit-scrollbar {
      display: none;
    }
    .comment {
      color: #ffffff;
      width: 580px;
      height: auto;
      margin: 5px 0 12px 30px;
      border: .5px #ffffff;
      display: flex;
      justify-content: left;
      align-items: center;
      p {
        position: relative;
        border-radius: 8px;
        max-height: 80px;
        width: 540px;
        padding: 20px 10px;
        font-size: 18px;
        overflow: hidden;
        word-break: break-all;
        transition: height 0.3s;
        padding-right: 35px;
        margin-bottom: 0;
        background-color: rgba(255, 255, 255, 0.6);
        i {
          position: absolute;
          right: 10px;
          bottom: 10px;
        }
        &.expanded-paragraph {
          height: auto;
          max-height: unset;
        }
      }
      &.expanded-comment{
        height: auto;
      }
    }
    &.scrollable-list {
      //overflow: scroll;
      //overflow-x: hidden;
    }
    &__avatar{
      top: 0;
      left: 0;
      z-index: 1;
      position: relative;
      width: 35px;
      height: 35px;
      border-radius: 50%;
      overflow: hidden;
      margin-right: 15px;
      background-position: center;
      background-size: cover;
      background-repeat: no-repeat;
    }:nth-child(2n) {
       right: 0;
     }
    &__avatar:nth-child(2n) {
     right: 0;
    }
  }
  &.comments-active {
    opacity: 1;
    width: auto;
    height: auto;
  }
}

.comments__left {
  opacity: 0;
  position: absolute;
  bottom: 0;
  right: 450px;
  transition: all ease-in-out .3s;
  transform: translate3d(0, 0, 0);
  max-height: calc(100vh - 160px);
  overflow-y: auto;
  width: 0;
  height: 0;
  .add-comment {
    position: relative;
    border-radius: 8px;
    width: 580px;
    height: 100px;
    margin-bottom: 15px;
    margin-left: 30px;
    background: rgba(255, 255, 255, 0.6);
    input {
      color: #ffffff;
      width: 515px;
      height: 90px;
      border: none;
      background: none;
      border-radius: .25rem;
      padding: .75rem 1rem;
      outline: none;
    }
    input::placeholder {
      color: rgba(255, 255, 255, 0.7);
    }
    button {
      border: none;
      background: none;
      i {
        color: white;
      }
    }
  }
  .add-comment:after {
    content: '';
    position: absolute;
    display: block;
    width: 0;
    z-index: 1;
    border-style: solid;
    border-width: 0 0 30px 30px;
    border-color: transparent transparent rgba(255, 255, 255, 0.6) transparent;
    top: 20%;
    transform: rotateY(180deg);
    right: -30px;
    margin-top: -10px;
  }
  &__list {
    display: flex;
    flex-direction: column;
    width: 650px;
    height: 350px;
    overflow-y: auto;
    overflow-x: hidden;
    &::-webkit-scrollbar {
      display: none;
    }
    .comment {
      color: #ffffff;
      width: 580px;
      height: auto;
      margin: 5px 0 12px 30px;
      border: .5px #ffffff;
      border-radius: 8px;
      display: flex;
      justify-content: left;
      align-items: center;
      p {
        position: relative;
        border-radius: 8px;
        max-height: 80px;
        width: 540px;
        padding: 20px 10px;
        font-size: 18px;
        overflow: hidden;
        word-break: break-all;
        transition: height 0.3s;
        padding-right: 35px;
        margin-bottom: 0;
        background-color: rgba(255, 255, 255, 0.6);
        i {
          position: absolute;
          right: 10px;
          bottom: 10px;
        }
        &.expanded-paragraph {
          height: auto;
          max-height: unset;
        }
      }
    }
    .comment:nth-child(2n) {
      margin-left: 30px;
    }
    &.scrollable-list{
      //overflow: scroll;
      //overflow-x: hidden;
    }
    &__avatar{
      top: 0;
      left: 0;
      z-index: 1;
      position: relative;
      width: 35px;
      height: 35px;
      border-radius: 50%;
      overflow: hidden;
      margin-right: 15px;
      background-position: center;
      background-size: cover;
      background-repeat: no-repeat;
    }:nth-child(2n) {
       right: 0;
     }
    &__avatar:nth-child(2n) {
      right: 0;
    }
  }
  &.comments-active {
    opacity: 1;
    width: auto;
    height: auto;
  }
}

