.react-switch-container {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 5px;

    &__choice {

        &--left {
            color: #FFF;
            margin-right: 10px;
        }
        &--right {
            color: #FFF;
            margin-left: 10px;
        }
    }
}
