.settings {
    opacity: 0;
    position: absolute;
    bottom: 70px;
    right: 80px;
    transition: all ease-in-out .3s;
    transform: translate3d(0, 0, 0);
    max-height: calc(100vh - 160px);
    overflow-y: auto;
    width: 0;
    height: 0;
    &__text {
        color: $dashboard-color;
        font-weight: bold;
    }
    &__themes {
        margin-bottom: 10px;
        &__list {
            display: flex;
            flex-wrap: wrap;
            width: 180px;
            .item {
                width: 35px;
                height: 35px;
                margin: 5px 10px 5px 0;
                border: .5px solid #ffffff;
                border-radius: 5px;
                display: flex;
                justify-content: center;
                align-items: center;
                cursor: pointer;
                &__checked {
                    font-size: 20px;
                    color: $dashboard-color;
                }
            }
        }
    }
    &__speed {
        margin-bottom: 10px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        &__slider {
            width: 80%;
            height: 16px;
            margin: 0 auto;
            margin-bottom: -20px;
        }
        .slider {
            position: relative;
            top: -8px;
            -webkit-appearance: none;
            appearance: none;
            width: 100%;
            background: rgba(255, 255, 255, 0.5);
            height: 2px;
            outline: none;
            -webkit-transition: .2s;
            transition: opacity .2s;
        }
        .slider:hover {
            opacity: 1;
        }
        .slider::-webkit-slider-thumb {
            -webkit-appearance: none;
            appearance: none;
            height: 16px;
            width: 3px;
            background: $white;
            cursor: pointer;
        }
        .slider::-moz-range-thumb {
            appearance: none;
            height: 16px;
            width: 3px;
            background: $white;
            cursor: pointer;
        }
        &__text {
            font-size: 100px;
            font-family: Arial;
            font-weight: 300;
            color: #FFF;
        }
    }
    &__links {
        display: flex;
        flex-direction: column;
        a {
            color: #fff !important;
        }
    }
    &.settings-active {
        opacity: 1;
        width: auto;
        height: auto;
    }
}
