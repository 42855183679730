.visited-list {
    display: flex;
    align-items: flex-end;
    position: absolute;
    left: 82px;
    bottom: 15px;
    max-width: calc(100vw - 365px);
    overflow-x: auto;
    &::-webkit-scrollbar {
        height: 8px;
    }
    &::-webkit-scrollbar-track {
        background-color: #0000;
    }
    &::-webkit-scrollbar-thumb {
        border-radius: 8px;
        background-color: #d8d8d8;
    }
    @media screen and (max-width: 640px) {
        max-width: calc(100vw - 160px);
    }
    &__items {
        display: flex;
    }
    &__item {
        margin: 10.5px;
        border-radius: 50%;
        overflow: hidden;
        width: 50px;
        height: 50px;
        cursor: pointer;
        div {
            display: flex;
            flex: 1;
            width: 50px;
            height: 50px;
            background-size: cover;
        }
        i {
            color: $dashboard-color;
            font-size: 46px;
            position: relative;
            left: 1px;
        }
    }
    .active-item {
        box-shadow: 0 0 10px #fff;
    }
}
