.dropzone {
    &__container {
        width: 100%;
        margin-bottom: 10px;
    }
    &__item {
        &__upload {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            align-items: center;
            margin-bottom: 10px;
            &__control {
                display: flex;
                flex: 1;
                justify-content: space-around;
                align-items: center;
                &__icon {
                    cursor: pointer;
                    color: #FFF;
                }
            }
            .button-item {
                background: transparent;
                border: 1px solid #FFF;
                color: #FFF;
                font-size: 18px;
                padding: 5px 15px;
                position: relative;
                outline: none;
                cursor: pointer;
            }
            .no-file-selected {
                color: #FFF;
                font-weight: bold;
            }
        }
        &__thumb {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 100%;
            height: 200px;
            border: 1px solid rgba(255, 255, 255, 0.5);
            padding: 5px;
            img {
                max-height: 100%;
                max-width: 100%
            }
        }
    }
}
