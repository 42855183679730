.floated {
  position: absolute;
  right: 0;
  color: #fff;

  &.first {
    right: -70px;
  }

  &.second {
    top: 330px;
    right: -120px;
  }

  &.third {
    top: 360px;
    right: -90px;
  }

  &.fourth {
    top: 390px;
    right: -85px;
  }
}
