.bubbles-container {
    position: relative;
    width: 100%;
    height: 100%;
    &__screen {
        position: absolute;
        height: inherit;
        width: inherit;
        top: 0;
        left: 0;
    }
}
.new-bubbles-viewport {
    z-index: 1;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    margin: 0 auto;
    perspective: 600px;
    perspective-origin: 50% 40%;
    will-change: perspective-origin;
    transform: translate3d(0, 0, 0);
    &__scene3D {
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        left: 50vw;
        top: 20vh;
        width: 0;
        height: 0;
        margin: 0;
        transform-style: preserve-3d;
        will-change: transform;
        //opacity: 0.99;
    }
}
