.theme {
    &-base {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: -999;
    }
    &-solid {
        &__bright-cyan {
            background: #30c9e8;
        }
        &__desaturated-cyan {
            background: #7acfd6;
        }
        &__strong-cyan {
            background: #0db4b9;
        }
        &__soft-cyan {
            background: #99e1d9;
        }
        &__dark-cyan {
            background: #83af9b;
        }
        &__soft-pink {
            background: #f070a1;
        }
        &__soft-red {
            background: #dea6b0;
        }
        &__dark-blue {
            background: #1e252d;
        }
    }
    &-gradient {
        &__light-blue {
            background: rgba(0, 0, 0, 0) linear-gradient(60deg, #96deda 0%, #50c9c3 100%);
        }
        &__pink {
            background: rgba(0, 0, 0, 0) linear-gradient(-20deg, #f794a4 0%, #fdd6bd 100%);
        }
        &__red {
            background: rgba(0, 0, 0, 0) linear-gradient(to right, #f78ca0 0%, #f9748f 19%, #fd868c 60%, #fe9a8b 100%);
        }
        &__blue {
            background: rgba(0, 0, 0, 0) linear-gradient(142deg, #0fb8ad 0%, #1fc8db 51%, #2cb5e8 75%);
        }
    }
    &-picture {
        &-1 {
            background-image: url("/images/background/1-image.jpg");
            background-position: center center;
            background-size: cover;
        }
        &-2 {
            background-image: url("/images/background/2-image.jpg");
            background-position: center center;
            background-size: cover;
        }
        &-3 {
            background-image: url("/images/background/3-image.jpg");
            background-position: center center;
            background-size: cover;
        }
        &-4 {
            background-image: url("/images/background/4-image.jpg");
            background-position: center center;
            background-size: cover;
        }
    }
    &-sky {
        & {
            background-image: url("/images/background/0_dark_sky.png");
            background-position: center center;
            background-size: cover;
        }
        &__preview {
            background-image: url("/images/background/sky-preview.png");
            background-position: center center;
            background-size: cover;
        }
    }
    &-stars {
        &-1 {
            background-image: url("/images/background/1_star.png");
            background-size: cover !important;
        }
        &-2 {
            background-image: url("/images/background/2_star.png");
            background-size: cover !important;
        }
        &-3 {
            background-image: url("/images/background/3_star.png");
            background-size: cover !important;
        }
        &-4 {
            background-image: url("/images/background/4_star.png");
            background-size: cover !important;
        }
        &-5 {
            background-image: url("/images/background/5_star.png");
            background-size: cover !important;
        }
        &-6 {
            background-image: url("/images/background/6_star.png");
            background-size: cover !important;
        }
    }
    &-clouds {
        &-1 {
            background-image: url("/images/background/1_cloud.png");
            background-size: cover !important;
        }
        &-2 {
            background-image: url("/images/background/2_cloud.png");
            background-size: cover !important;
        }
        &-3 {
            background-image: url("/images/background/3_cloud.png");
            background-size: cover !important;
        }
    }
    &-forest {
        & {
            background-image: url("/images/background/0_forest_sm.jpg");
            background-position: center center;
            background-size: cover;
            background-repeat: repeat-x;
        }
        &__preview {
            background-image: url("/images/background/forest-preview.jpg");
            background-position: center center;
            background-size: cover;
        }
        &-1 {
            background-image: url("/images/background/1_forest_sm.png");
        }
        &-2 {
            background-image: url("/images/background/2_forest_sm.png");
        }
        &-3 {
            background-image: url("/images/background/3_forest_sm.png");
        }
        &-4 {
            background-image: url("/images/background/4_forest_sm.png");
        }
    }
    &-container {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        perspective-origin: 50% 65%;
        perspective: 100vw;
        z-index: -998;
        @media screen and (max-width: 1024px) {
            perspective: 200vw;
        }
        @media screen and (max-width: 768px) {
            perspective: 300vw;
        }
        @media screen and (max-width: 450px) {
            perspective: 700vw;
        }
        div {
            position: absolute;
            display: block;
            top: 0;
            left: 0;
            width: 100vw;
            height: 100vh;
            transform-style: preserve-3d;
            background-position: center center;
            background-repeat: repeat-x;
        }
    }
}
